import { default as _91_46_46_46pageResolver_93mRP2dIxeMWMeta } from "/root/work/proverky.net/proverky.net/pages/[...pageResolver].vue?macro=true";
import { default as thanksDRKkNnw3pGMeta } from "/root/work/proverky.net/proverky.net/pages/thanks.vue?macro=true";
export default [
  {
    name: "pageResolver",
    path: "/:pageResolver(.*)*",
    component: () => import("/root/work/proverky.net/proverky.net/pages/[...pageResolver].vue").then(m => m.default || m)
  },
  {
    name: "thanks",
    path: "/thanks",
    component: () => import("/root/work/proverky.net/proverky.net/pages/thanks.vue").then(m => m.default || m)
  }
]